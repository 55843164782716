<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Transaksi
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="12">
          <label for="tanggal">Bulan - Tahun</label>
          <date-picker
            style="width: 100%"
            id="tanggal"
            format="MMMM - YYYY"
            type="month"
            v-model="dataFilter.tanggal"
          ></date-picker>
        </b-col>
        <b-col cols="6">
          <label for="ms_poli_id">Poli</label>
          <Multiselect
            id="ms_poli_id"
            v-model="dataFilter.ms_poli_id"
            :options="listPoli"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_poli"
            track-by="ms_poli_id"
            placeholder="-- Pilih Poli --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="6">
          <label for="pembayaran">Pembayaran</label>
          <Multiselect
            id="pembayaran"
            v-model="dataFilter.pembayaran"
            :options="listPembayaran"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            track-by="value"
            placeholder="-- Pilih Pembayaran --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="12">
          <div class="">
            <div class="d-flex justify-content-between align-items-end">
              <h5 class="custom-title-card mt-2"><strong>Puskesmas</strong></h5>
              <b-form-checkbox
                id="select-all-1"
                name="select-all-1"
                v-model="dataFilter.allPoli"
                :value="true"
                :unchecked-value="false"
                @input="pilihSemuaPoli()"
              >
                <div style="margin-top: 2px;">Pilih Semua</div>
              </b-form-checkbox>
            </div>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-puskesmas"
                v-model="dataFilter.bulk_puskesmas_id"
                :aria-describedby="ariaDescribedby"
                name="checkbox-puskesmas"
                @change="dataFilter.allPoli = false"
              >
                <b-form-checkbox :value="item.id" v-for="(item, key) of listPuskesmas" :key="key">
                  <div id="puskesmas" class="" style="margin-top: 2px;">{{item.nama_puskesmas}}</div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <canvas id="myChart" ref="pendapatan_harian" style="width: 100%"></canvas>
        </b-col>
        <!-- <b-col cols="12">
          <b-table
            class="px-3"
            :items="listTransaksi"
            :fields="fieldsPendapatanHarian"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(tanggal)="item">{{$moment(item.item.tanggal).format('LL')}}</template>
            <template #cell(jumlah)="item">{{$numberFormat(item.item.jumlah, 'idr')}}</template>
          </b-table>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  components:{
  },
  data() {
    return {
      busy: false,
      myChart: null,
      dataFilter: {
        tanggal: null,
        ms_poli_id: null,
        pembayaran: null,
        bulk_puskesmas_id: [],
        allPoli: false,
      },
      listPuskesmas: [],
      listPoli: [],
      listPembayaran: [
        { value: 'tunai', text: 'Tunai' },
        { value: 'bpjs', text: 'BPJS' },
      ],
      listTransaksi: [],
      fieldsPendapatanHarian: [
        {
          key: "tanggal",
          label: "Tanggal",
          sortDirection: "desc",
          sortable: true,
          class: "text-left",
        },
        {
          key: "jumlah",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
      ],
      
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.getData()
    vm.myChart = new Chart(this.$refs.pendapatan_harian, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Total transaksi',
          data: [],
          backgroundColor: [
            '#6574cd',
          ],
          borderColor: [
            '#9561e2',
          ],
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
        },
      }
    })
    vm.myChart.update()
    vm.reset()
  },
  methods: {
    pilihSemuaPoli(){
      const vm = this
      vm.dataFilter.bulk_puskesmas_id = []
      console.log('vm.dataFilter.allPoli', vm.dataFilter.allPoli)
      if(vm.dataFilter.allPoli){
        // vm.dataFilter.allPoli = true
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          vm.dataFilter.bulk_puskesmas_id.push(vm.listPuskesmas[i].id)
        }
      }
    },
    async cari(){
      const vm = this
      vm.busy = false
      try {
        // console.log('vm.dataFilter.tanggal', vm.dataFilter.tanggal)
        const res = await vm.$axios.post('/pembayaran_tagihan/grafik_transaksi', {
          tahun: vm.$moment(vm.dataFilter.tanggal).format('YYYY'),
          bulan: vm.$moment(vm.dataFilter.tanggal).format('MM'),
          ms_poli_id: vm.dataFilter.ms_poli_id ? vm.dataFilter.ms_poli_id.ms_poli_id : null,
          metode_pembayaran: vm.dataFilter.metode_pembayaran ? vm.dataFilter.pembayaran.value : null,
          bulk_puskesmas_id: vm.dataFilter.bulk_puskesmas_id,
        })
        // console.log('grafik_pendapatan_harian', res)
        if(res.data.status == 200){
          vm.listTransaksi = res.data.data
          const label = []
          const data = []
          for (let i = 0; i < vm.listTransaksi.length; i++) {
            const x = vm.listTransaksi[i];
            label.push(vm.$moment(x.tanggal).format('DD'))
            data.push(x.total)
          }
          vm.myChart.data.labels = label;
          vm.myChart.data.datasets[0].data = data;
          vm.myChart.update()
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      const vm = this
      vm.busy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listPuskesmas = await vm.$axios.post('/puskesmas/list')
        vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      // console.log('vm.$store.state.tanggal_sesi', vm.$store.state.tanggal_sesi)
      vm.dataFilter = {
        tanggal: new Date(vm.$store.state.tanggal_sesi),
        ms_poli_id: null,
        pembayaran: null,
        bulk_puskesmas_id: [],
        allPoli: false,
      }
      vm.cari()
    }
  },
}
</script>